@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import './_variables';
@import './cards';
@import './modals';

::selection {
    background: $blue;
}

html {
    --antd-wave-shadow-color: $blue;
}

body {
    font-family: 'Roboto', sans-serif;
    color: $grey2;
}

h1 {
    font-size: 2em;
}

textarea.ant-input {
    resize: vertical !important;
    //max-height: unset !important;
}

pre {
    white-space: pre-wrap;
}

.input-has-value {
    border: 2px solid $green !important;
}

.offline-message {
    position: absolute;
    top: 0;
    left: 0;
    height: 40px;
    width: 100%;
    z-index: 9999;

    .ant-alert {
        .anticon {
            color: $yellow;
            font-size: 20px;
        }

        .ant-alert-description {
            text-align: center;
            font-size: 16px;
            font-weight: bold;
        }
    }
}

.ant-checkbox-wrapper {
    font-family: 'Roboto', sans-serif;
    color: $grey2;

    span {
        color: $grey2;
    }
}

.text-center {
    text-align: center;
}

.text-green {
    color: $green;

    * {
        color: $green;
    }
}

.text-red {
    color: $red;

    * {
        color: $red;
    }
}

.text-600 {
    font-weight: 600 !important;
}

.text-400 {
    font-weight: 400 !important;
}

.text-left {
    text-align: left !important;
}

.text-italics {
    font-style: italic;
}

.font-24 {
    font-size: 24px !important;
}

.font-18 {
    font-size: 18px !important;
}

.font-16 {
    font-size: 16px;
}

.font-14 {
    font-size: 14px;
}

.font-12 {
    font-size: 12px;
}

.font-10 {
    font-size: 10px;
}

.uppercase {
    text-transform: uppercase;
}

.bold {
    font-weight: bold;
}

.p-0 {
    padding: 0 !important;
}

.p-1 {
    padding: 10px !important;
}

.p-2 {
    padding: 20px !important;
}

.p-3 {
    padding: 30px !important;
}

.pb-0 {
    padding-bottom: 0 !important;
}

.pb-1 {
    padding-bottom: 10px !important;
}

.pb-2 {
    padding-bottom: 20px !important;
}

.pb-3 {
    padding-bottom: 30px !important;
}

.pr-0 {
    padding-right: 0 !important;
}

.pr-1 {
    padding-right: 10px !important;
}

.pr-2 {
    padding-right: 20px !important;
}

.pr-3 {
    padding-right: 30px !important;
}

.pl-0 {
    padding-left: 0 !important;
}

.pl-1 {
    padding-left: 10px !important;
}

.pl-2 {
    padding-left: 20px !important;
}

.pl-3 {
    padding-left: 30px !important;
}

.pt-0 {
    padding-top: 0 !important;
}

.pt-1 {
    padding-top: 10px !important;
}

.pt-2 {
    padding-top: 20px !important;
}

.pt-3 {
    padding-top: 30px !important;
}

.m-0 {
    margin: 0 !important;
}

.m-1 {
    margin: 10px !important;
}

.m-1rem {
    margin: 1rem;
}

.m-2 {
    margin: 20px !important;
}

.m-3 {
    margin: 30px !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.mb-1 {
    margin-bottom: 10px !important;
}

.mb-half-rem {
    margin-bottom: 0.5rem;
}

.mb-p1-rem {
    margin-bottom: 0.1rem;
}

.mb-1rem {
    margin-bottom: 1rem;
}

.mb-2 {
    margin-bottom: 20px !important;
}

.mb-3 {
    margin-bottom: 30px !important;
}

.mr-0 {
    margin-right: 0 !important;
}

.mr-1 {
    margin-right: 10px !important;
}

.mr-15 {
    margin-right: 15px !important;
}

.mr-2 {
    margin-right: 20px !important;
}

.mr-3 {
    margin-right: 30px !important;
}

.ml-0 {
    margin-left: 0 !important;
}

.ml-1 {
    margin-left: 10px !important;
}

.ml-2 {
    margin-left: 20px !important;
}

.ml-3 {
    margin-left: 30px !important;
}

.mt-0 {
    margin-top: 0 !important;
}

.mt-1 {
    margin-top: 10px !important;
}

.mt-2 {
    margin-top: 20px !important;
}

.mt-3 {
    margin-top: 30px !important;
}

.mtn-1 {
    margin-top: -10px !important;
}

.mtn-2 {
    margin-top: -20px !important;
}

.mtn-3 {
    margin-top: -30px !important;
}

.overflow-scroll {
    overflow: scroll;
}

.overflowY-auto {
    overflow-y: auto;
}

.text-right {
    text-align: right;
}

.h-100 {
    height: 100% !important;
}

.w-100 {
    width: 100% !important;
}

.w-75 {
    width: 75% !important;
}

.w-50 {
    width: 50% !important;
}

.w-25 {
    width: 25% !important;
}

.m0-auto {
    margin: 0 auto;
}

.d-block {
    display: block !important;
}

.d-inline-flex {
    display: inline-flex;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-align-end {
    display: flex;
    align-items: flex-end !important;
}

.lh-normal {
    line-height: normal !important;
}

.w-auto {
    width: auto !important;
}

.flex-align {
    display: flex;
    align-items: center;
}

.flex-justify {
    display: flex !important;
    justify-content: center !important;
}

.text-button {
    text-decoration: underline;
    color: $blue;
}

.ant-typography {
    &.ant-typography-success {
        color: $green !important;
    }

    &.ant-typography-warning {
        color: $yellow !important;
    }

    &.ant-typography-danger {
        color: $red !important;
    }
}

.ant-tooltip {

    .ant-typography,
    .ant-typography-copy {
        color: $white;
    }
}

.ant-btn {
    border-radius: 100px !important;
    border-color: $grey !important;
}

.ant-btn-link {
    border: none !important;

    span {
        color: $blue;
    }
}

.ant-btn-primary {
    background-color: $blue;
    border: none !important;

    span {
        color: $white !important;
    }
}

.ant-tag {
    border-radius: 100px !important;
}

#root {
    display: flex;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    max-height: 100vh;

    >.spinner {
        align-self: center;
        justify-self: center;
    }

    .app-error {
        align-self: center;
        justify-self: center;
        height: 50px;
        width: 100%;
        text-align: center;
        margin: 0 20%;
    }
}

.hamburger {
    & .ant-layout-sider-zero-width-trigger {
        top: 10px;
        background-color: #0079c2 !important;
        color: white !important;
        width: 30px;
        left: 0;
    }
}

.non-production-env {

    .ant-page-header,
    .ant-drawer-header {
        background-color: $red !important;
        color: $white;
        border-radius: 0;

        *>p,
        *>span:not(.ant-select-selection-item):not(.ant-tag) {
            color: $white;
        }

        .ant-btn-dangerous {
            background-color: $darkred !important;
        }
    }
}

.beta-env {

    .ant-page-header,
    .ant-drawer-header {
        background-color: $blue !important;

        .ant-btn-dangerous {
            background-color: $blue !important;
        }
    }
}

.main {
    display: flex;
    width: 100%;
    height: 100%;
    min-height: 100vh;

    &.offline {
        margin-top: 58px;
        height: 90%;
        min-height: 90vh;
    }

    &.non-production-env {
        border-left: 5px solid $red;

        @media print {
            border-left: none;
        }

        .ant-menu-item {
            &.non-prod {
                background: $red;
                color: $white;
                font-weight: bold;
            }
        }

        .site-layout {
            .ant-page-header {
                background-color: $red !important;

                .ant-page-header-heading-title {
                    color: $white;
                }
            }
        }
    }

    &.beta-env {
        border-left: 5px solid $blue;

        .ant-menu-item {
            &.non-prod {
                background: $blue;
            }
        }

        .site-layout {
            .ant-page-header {
                background-color: $blue !important;
            }
        }
    }

    .ant-layout {

        // Sidebar
        .ant-layout-sider {
            width: $sider-width;
            background: $white;

            .ant-layout-sider-children {
                .logo {
                    margin-top: 0;
                    // 60% is good for position / size as long as the sidebar menu is 300px
                    background: url(../../../shared/images/logo.png) no-repeat 60% 50%;
                    background-size: 60%;
                    height: 61px;
                    border-bottom: 1px solid $grey;
                    border-right: 1px solid $grey;
                }

                .ant-menu {
                    border: none;

                    .ant-menu-item {
                        font-size: 16px;
                        justify-items: center;
                        height: 50px;
                        line-height: 50px;

                        .ant-tag {
                            position: absolute;
                            right: 10px;
                            top: 10px;
                            background: $grey2;
                            color: $white;
                            border-radius: 100px;
                            font-size: 12px;
                            letter-spacing: -0.18px;
                        }

                        .ant-badge {
                            margin-left: 10px;
                        }

                        &.ant-menu-item-selected {
                            background: $off-white;
                            border-top: 1px solid $grey;
                            border-bottom: 1px solid $grey;
                            border-left: 5px solid $blue;
                            border-right: none;
                            color: $blue;

                            .anticon,
                            .ant-icon {
                                color: $blue;
                            }

                            &::after {
                                display: none;
                            }
                        }
                    }

                    .ant-menu-submenu-disabled {
                        display: none !important;
                    }
                }
            }
        }
    }

    .site-layout {
        height: 100vh;

        .ant-page-header {
            padding: 10px 35px;
            background: $white !important;
            border-bottom: 1px solid $grey;

            .ant-page-header-heading-title {
                color: $grey2;
            }
        }

        .ant-layout-content {
            background: $off-white;

            .content {
                margin: 0 !important; // Remove left/right scrollbar on views
                border-left: 1px solid $grey;

                @media (min-width: 844px) {
                    padding: 35px;
                }

                @media (max-width: 767px) {
                    padding: 10px;
                }
            }
        }
    }
}

.spinner {
    height: 100vh;

    .ant-spin {
        color: $blue;
        margin-top: 25%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;

        .ant-spin-dot-item {
            background-color: $blue;
        }
    }

    &.white {
        .ant-spin {
            .ant-spin-dot-item {
                background-color: $white;
            }

            .ant-spin-text {
                color: $white;
            }
        }
    }
}

.mobile-modal {
    top: 0 !important;
    padding: 0 !important;

    .ant-modal-content {
        height: calc(100vh - 16px) !important;
        display: flex !important;
        flex-direction: column;
        overflow-y: auto;
        flex: 1;
    }

    .ant-modal-footer {
        margin-top: auto;
    }
}

.dropzone-container {
    aside {
        margin-bottom: 15px;
    }

    .ant-row {
        margin-bottom: 0 !important;
    }

    .dropzone {
        background: $off-white;
        border: 2px dashed $grey;
        border-radius: 4px;
        padding: 20px;
        cursor: pointer;
        text-align: center;
    }
}

.ant-collapse {
    border-color: $grey !important;
    border: none !important;
    background: transparent !important;

    >.ant-collapse-item {
        margin-bottom: 15px;
        border: 1px solid $grey !important;

        >.ant-collapse-header {
            display: flex;
            font-size: 20px;
            background: $white !important;
            color: $grey2 !important;
            padding: 10px 15px !important;
            justify-items: center;

            .ant-collapse-extra {
                position: absolute;
                right: 110px;
                border-right: 1px solid $grey;
                padding-right: 20px;

                .ant-tag {
                    position: relative;
                    top: -4px;
                    margin-right: 20px;
                    font-weight: bold;
                    font-size: 12px;
                }
            }

            .ant-collapse-arrow {
                position: absolute !important;
                right: 15px !important;
                margin-right: 0 !important;
                font-size: 14px !important;
                color: $blue !important;

                .anticon {
                    color: $blue !important;
                    margin-left: 10px;
                }
            }
        }

        >.ant-collapse-content {
            border-color: $grey !important;

            >.ant-collapse-content-box {
                padding: 0 !important;
            }
        }
    }
}

.ant-card {
    .ant-card-head {
        color: $grey2;
        border: none;
        padding: 15px 35px;
        min-height: 20px;

        .ant-card-head-title {
            padding: 0;

            h3 {
                margin-top: 10px;
                color: #6c8095;
            }
        }
    }

    .ant-card-body {
        padding: 15px 35px;

        .ant-form-item-label {
            >label {
                color: $grey2;
                font-weight: bold;
                font-size: 14px;
            }
        }
    }
}

.float-right {
    float: right;
}

.float-left {
    float: left;
}

.clear-both {
    clear: both;
}

.ant-select-multiple {
    .ant-select-selection-item {
        font-size: 12px;
        background: $grey2 !important;
        color: $white !important;
        padding-right: 0;
        position: relative;

        .ant-select-selection-item-content {
            background: $grey2;
            padding-right: 20px;
            color: $white !important;
        }

        .ant-select-selection-item-remove {
            position: absolute;
            top: 0;
            right: 0;
            background: $grey3 !important;
            color: $white !important;
            padding: 0 5px;
            text-align: center;

            &:hover {
                opacity: 0.8;
            }
        }
    }
}

.auth-error {
    width: 100%;
}

.chart-updated-date {
    font-size: 12px;
    margin-top: -1px;
}

.ant-alert-description {
    ul {
        margin: 0;
        padding-inline-start: 15px;
    }
}

.pcc-login-button {
    color: $white;

    img {
        background-color: $white;
        border-radius: 3px;

        &:hover {
            box-shadow: -1px 2px 5px 0 rgba(0, 0, 0, 0.15);
        }
    }
}

.ant-input-search {
    .ant-input-search-button {
        background: $off-white;
        border-radius: 0 !important;

        .anticon-search {
            margin-left: -1px;
        }
    }
}

.Toastify {
    hr {
        height: 0;
        border: none;
        border-top: 1px solid $darkred;
    }
}

.error-modal {
    .ant-modal-content {
        .ant-modal-body {
            padding: 0px !important;

            .ant-modal-confirm-body {
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 20px;

                span {
                    text-align: center;
                    font-size: 32px;

                    &.anticon-close-circle {
                        float: unset !important;
                        font-size: 7rem;
                        margin: 0 0 1.5rem 0;
                    }
                }

                .ant-modal-confirm-content {
                    margin-top: 0;
                }

                ul {
                    margin-bottom: 0;
                }
            }

            .ant-modal-confirm-btns {
                width: 100%;
                margin-top: 0px;

                button {
                    border-radius: 0px 0px 4px 4px !important;
                    background-color: $red;
                    width: 100%;
                    height: 60px;
                }
            }
        }
    }
}

.note-banner-alert {
    margin: -10px -20px 10px -20px !important;
}

@media (max-width: 575px) {
    .p-xs-0 {
        padding: 0 !important;
    }

    .pt-xs-15 {
        padding-top: 15px !important;
    }
}

.ant-select-selection-placeholder {
    color: #bfbfbf !important;
}

.flex-direction-column {
    flex-direction: column;
}

.justify-content-space-between {
    justify-content: space-between;
}

.flex-align-items-center {
    align-items: center;
}

.element-with-rendered-html {
    white-space: pre-line;
}

// Fix for drawer header width after upgrading to 4.18.*
.ant-drawer-header,
.ant-drawer-header-title,
.ant-drawer-title {
    width: 100%;
}

// Fix for drawer close icon after upgrading to 4.18.*
.ant-drawer-close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 20px;
}

.open-note-mobile {
    align-self: flex-start;
}

.schedule-table {
    button {
        border-left: 1px solid $grey !important;

        &:hover {
            border-left: 1px solid $grey !important;
        }

        &.btn-light-blue {
            background-color: $off-white !important;
            border: 1px solid $blue !important;
            border-left: 1px solid $blue !important;

            span {
                color: $blue !important;
            }
        }
    }
}

.white {
    color: $white;
}

.print-table-1 {
    * {
        font-size: 0.1vw !important;
    }
}

.print-table-2 {
    * {
        font-size: 0.2vw !important;
    }
}

.print-table-3 {
    * {
        font-size: 0.3vw !important;
    }
}

.print-table-4 {
    * {
        font-size: 0.4vw !important;
    }
}

.print-table-5 {
    * {
        font-size: 0.5vw !important;
    }
}

.print-table-6 {
    * {
        font-size: 0.6vw !important;
    }
}

.print-table-7 {
    * {
        font-size: 0.7vw !important;
    }
}

.print-table-8 {
    * {
        font-size: 0.8vw !important;
    }
}

.print-table-9 {
    * {
        font-size: 0.9vw !important;
    }
}


.ant-table-clickable-row td {
    background-color: transparent !important;
}

.ant-table-clickable-row {
    background-color: #ffffff !important;
    cursor: pointer;

    &:hover {
        background-color: #f5f7f8 !important;
    }
}